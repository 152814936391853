import React from 'react'
import styled from 'styled-components'
import { Container, Text } from './'

const Wrapper = styled.header``

const Title = styled.div`
  text-align: center;
  padding: 1rem 0;
`

export const Header = () => (
  <Wrapper>
    <Container flex>
      <Title>
        <Text family="bitter" as="h1">
          James Symons
        </Text>
      </Title>
    </Container>
  </Wrapper>
)
