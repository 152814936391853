import { generateMedia } from 'styled-media-query'

const breakpoints = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
}

// used for styled-components easy media queries
export const media = generateMedia({
  xs: `${breakpoints.xs}px`,
  sm: `${breakpoints.sm}px`,
  md: `${breakpoints.md}px`,
  lg: `${breakpoints.lg}px`,
  xl: `${breakpoints.xl}px`,
})

const colors = {
  bg: '#fafafa',
  black: 'rgba(0,0,0,.87)',
}

export const theme = {
  fonts: {
    bitter: `'Bitter', serif`,
    montserrat: `'Montserrat', sans-serif`,
  },
  fontWeights: {
    body: 400,
  },
  // fontSizes,
  colors,
}
