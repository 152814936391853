import React from 'react'
import styled, { css } from 'styled-components'
import { Header, Footer } from './'

const PageOuter = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.fonts.montserrat};
  /* font-family: ${(props) => props.theme.fonts.bitter}; */
  font-size: 20px;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.bg};
`

const PageInner = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  z-index: 0;
`

const FooterShadow = styled.div`
  background: linear-gradient(180deg, rgba(217, 219, 224, 0) 0%, #d9dbe0 100%);
  height: 500px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`

const Main = styled.main`
  flex: 1 0 auto;
`

export const srOnly = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`

export const clearfix = css`
  &:before,
  &:after {
    display: table;
    content: '';
    clear: both;
  }
`

export const Container = styled.div`
  ${clearfix}
  margin: 0 auto;
  max-width: ${(props) => (props.flex ? 'auto' : '1200px')};
  /* padding: ${(props) => (props.flex ? '0 30px' : '0')}; */
  padding: 0 30px;
`

const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`

export const Image = ({ src, alt, ...props }) => (
  <ImageWrapper {...props}>
    <img src={src} alt={alt} />
  </ImageWrapper>
)

export const Page = ({ children }) => (
  <PageOuter>
    <PageInner>
      <Header />
      <Main>{children}</Main>
      <Footer />
      <FooterShadow />
    </PageInner>
  </PageOuter>
)
