import React from 'react'
import { srOnly } from './'
import styled from 'styled-components'
import {
  TiSocialTwitterCircular,
  TiSocialLinkedinCircular,
} from 'react-icons/ti'

const Wrapper = styled.footer``

const FooterName = styled.p`
  /* font-family: ${(props) => props.theme.fonts.bitter}; */
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
`

const FooterText = styled.p`
  text-align: center;
  font-size: 16px;
  color: #999;
`

const SocialNav = styled.ul`
  width: 100px;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
`

const Socialli = styled.li``

const SocialLink = styled.a`
  color: #999;
  font-size: 40px;
`

const SocialSpan = styled.span`
  ${srOnly}
`

const thisYear = new Date().getFullYear()

export const Footer = () => (
  <Wrapper>
    <SocialNav>
      <Socialli>
        <SocialLink href="https://twitter.com/coconewty" target="_blank">
          <TiSocialTwitterCircular />
          <SocialSpan>Twitter</SocialSpan>
        </SocialLink>
      </Socialli>
      <Socialli>
        <SocialLink
          href="https://www.linkedin.com/in/jasymons/"
          target="_blank"
        >
          <TiSocialLinkedinCircular />
          <SocialSpan>Linkedin</SocialSpan>
        </SocialLink>
      </Socialli>
    </SocialNav>
    <FooterName>James Symons</FooterName>
    <FooterText>
      &copy; Copyright {thisYear}. All rights reserved.
      {/* Powered by <a href="#">Gatsby</a>. */}
    </FooterText>
  </Wrapper>
)
