// import React from 'react'
// import { Container, Text } from './'
import styled from 'styled-components'

export const Card = styled.div`
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`

Card.Section = styled.div`
  padding: 10px;
  & + & {
    border-top: 1px solid #eee;
  }
`
