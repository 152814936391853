import React from "react"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import { theme } from "./"
import "normalize.css"
import "./global.css"
import favicon32 from "../images/favicon-32x32.png"
import favicon16 from "../images/favicon-16x16.png"
import favicon from "../images/favicon.ico"

export const Provider = props => (
  <>
    <Helmet>
      <title>
        James Symons front end developer and UX designer in Bristol, UK
      </title>
      <meta
        name="description"
        content="James Symons front end developer and UX designer in Bristol, UK"
      />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="icon" href={favicon} type="image/x-icon" />
    </Helmet>
    <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
  </>
)
