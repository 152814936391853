import React from "react"
import styled from "styled-components"
import { media } from "../theme"
import { Card, Image, clearfix, TextBlock } from "./"
// import profileImage from "../images/james-symons-profile.jpg"

const Wrapper = styled.div`
  ${clearfix}
`

const ProfileImage = styled(Image)`
  float: left;
  height: 100px;
  width: 100px;
  margin: 0 10px 5px 0;
  border-radius: 50%;
  overflow: hidden;
  ${media.greaterThan("md")`
    height: 300px;
    width: 300px;
  `}
`

const TextContent = styled(TextBlock)`
  /* float: left; */
`

export const Profile = () => (
  <>
    <h2>Profile</h2>
    <Card>
      <Card.Section>
        <Wrapper>
          <ProfileImage
            src="https://res.cloudinary.com/james-symons/image/upload/w_1300,h_1300,c_crop,x_800,y_200,e_art:refresh/h_300,w_300,c_scale/v1599735967/IMG_20170818_154812433_qwulyn.jpg"
            alt=""
          />
          <TextContent>
            <p>
              <strong>Hi, I'm James.</strong>
            </p>
            <p>
              I'm a front end developer and UX designer with a decade of
              experience. I live in Bristol, UK with my wife and daughter.
            </p>
            <p>
              The web has changed a lot since I started. Gone are the days of
              supporting quirks in IE 5.5, but the basic building blocks are the
              same - clean, semantic and accessible HTML, sharp and efficient
              styling, and feature-packed JavaScript.
            </p>
          </TextContent>
        </Wrapper>
      </Card.Section>
    </Card>
  </>
)
