import styled from 'styled-components'

export const TextBlock = styled.div`
  line-height: 1.5;
`

export const Text = styled.span`
  /* font-family: ${(props) =>
    props.family === 'bitter'
      ? props.theme.fonts.bitter
      : props.theme.fonts.montserrat}; */
`
